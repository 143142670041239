.planningHeaderImage {
  width: 20px;
  margin-right: 9px;
  cursor: pointer;
}

.nav-tabs .nav-item.nav-link.active {
  padding: 10px 38px;
  border-radius: 4px;
  border: solid 1px #003da8;
  background-color: #005dff;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  margin-right: 15px;
}
.nav-tabs .nav-item.nav-link {
  flex-grow: 0;
  padding: 10px 38px;
  border-radius: 4px;
  border: solid 1px #fff;
  border-bottom: 2px solid #000;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 13px;
  margin-right: 15px;
}
.nav-tabs {
  border-bottom: 0px;
}

.planningCardStyle {
  padding: 17px 12px 0px 12px;
  border: solid 1px #dfe0eb;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 15px;
}

.planningCardHeader {
  display: flex;
  background-color: #f0f0f0;
}

.planningCardSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verticalCardLine {
  width: 3px;
  height: 35px;
  margin: 1px 18px 17px 15px;
  background-color: #e5e5e5;
}

.planningQuantityText {
  font-size: 16px;
  font-family: Inter;
  font-weight: bold;
  text-align: center;
}

.mtText {
  font-size: 13px;
  font-weight: 500;
}

.planningLabelText {
  margin-top: -5px;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  text-align: center;
  color: #6c757d;
}

.mpPlanningLabelText {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  width: 70px;
  color: #6c757d;
}

.mpQuantityText {
  margin-top: 7px;
  font-size: 16px;
  font-weight: bold;
  color: #20214f;
}

.mpQuantityText .text-style-1 {
  font-size: 12px;
  margin-left: 50px;
}

.mpQuantityText .text-style-2 {
  font-size: 10px;
  font-weight: 100;
  margin-left: 5px;
}

.Total-Target {
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  color: #000;
}

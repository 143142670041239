.document-history-container {
  .scrollbar-container {
    height: 100vh;
  }
  .expanded {
    position: fixed;
    right: 0;
    transition: 0.5s ease-in-out;
    top: 50px;
    z-index: 1000;
  }

  .z-100 {
    z-index: 1000;
  }

  .not-expanded {
    margin-right: -725px;
    position: fixed;
    right: 0;
    transition: 0.5s ease-in-out;
    top: 50px;
    z-index: 1000;
  }
  .documenthistory {
    background-color: #fff;
  }
  .documenthistory {
    background-color: #fff;

    .border-dashed {
      border-top: 1px dashed;
      border-color: #c0b8b8;
    }
    .close {
      font-weight: 100;
      font-size: 34px;
      padding: 1rem 0;
    }
    box-shadow: -10px 20px 45px #00000029;
    .documentheading {
      .title {
        font-size: 1rem;
        display: flex;
        align-items: center;
        font-family: font-bold;
        .rejected {
          color: #ff7272;
        }
      }
      .view-docu {
        font-size: 0.8rem;
        color: #00c4ff;
        opacity: 1;
        cursor: pointer;
      }
    }
    .author {
      label {
        font-size: 1rem;
      }
      p {
        font-size: 0.7143rem;
        color: #7f7f7f;
      }
    }
    .mentions {
      margin: 1em 0;
    }

    .mentions--singleLine .mentions__control {
      display: inline-block;
    }
    .mentions--singleLine .mentions__higlighter {
      padding: 1px;
      //border: 2px inset transparent;
      // background-color: #f5f5f5 !important;
    }
    .mentions--singleLine .mentions__input {
      padding: 5px;
      border: 2px inset;
    }

    .mentions--multiLine .mentions__control {
      //font-family: monospace;
      font-size: 14px;
      border: 1px solid silver;
    }
    .mentions--multiLine .mentions__highlighter {
      padding: 9px;
      min-height: 100px;
    }
    .mentions--multiLine .mentions__input {
      padding: 9px;
      min-height: 63px;
      outline: 0;
      border: 0;
    }

    .mentions__suggestions__list {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
    }

    .mentions__suggestions__item {
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .mentions__suggestions__item--focused {
      background-color: #f5f5f5;
    }

    .mentions__mention {
      position: relative;
      z-index: 1;
      color: #212529;
      pointer-events: none;
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 0.9rem;
      background-color: #dadada;
    }
  }
}
